<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.date }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Description <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.description }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.type }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Transaction Code <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.transactionCode }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Amount <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ formatPrice(form.amount) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.bankName }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.number }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "detail-bank-statement",
  props: {
    form: Object,
  },
  data: () => ({
    valid: true,
  }),

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
